<template>
  <page-head-wrapper>
    <!-- 搜索栏 -->
    <div class="mp-table-search-box">
      <a-form-model layout="inline"
        :form="searchForm"
        @submit="handleSearchSubmit"
        @submit.native.prevent
      >
        <a-form-model-item>
          <a-input style="width: 160px" v-model="searchForm.applicationName" placeholder="请输入应用名称"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-input style="width: 160px" v-model="searchForm.applicationCompany" placeholder="请输入所属企业"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select style="width: 104px" v-model="searchForm.applicationState" default-value=""
            :getPopupContainer="node => node.parentNode || document.body"
          >
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option value="y">
              启用
            </a-select-option>
            <a-select-option value="n">
              禁用
            </a-select-option>
          </a-select>
        </a-form-model-item>    
        <a-form-model-item>    
          <a-button class="mp-grey-btn" html-type="submit">
            查询
          </a-button>
          <a-button class="mp-grey-btn ml-8" @click="resetSearchForm">
            重置
          </a-button>
        </a-form-model-item>    
      </a-form-model>

      <div class="right-box">
        <a-button type="primary" @click="showDrawer">新增应用</a-button>
      </div>
    </div>
    
    <!-- 列表 -->
    <div class="mp-table-wrap">
      <a-table :columns="columns" 
        :data-source="data" 
        rowKey="applicationId"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
      >   
          <div slot="serial" slot-scope="text,record,index">{{index+1}}</div>
          <div slot="status" slot-scope="text">
            <a-tag color="#E9EDF2" v-if="text === 'y'">
              <a-badge color="#52C41A" text="启用"/>
            </a-tag>
            <a-tag color="#E9EDF2" v-if="text === 'n'">
              <a-badge color="#FF3B30" text="禁用" />
            </a-tag>
            <a-tag color="#E9EDF2" v-if="text === 'p'">
              <a-badge color="#ffe58f" text="待审核" />
            </a-tag>
          </div>
          <div slot="handles" slot-scope="text, record, index">
              <a-button type="link" size="small" @click="toDetail(record)">查看详情</a-button>
              <a-button type="link" size="small" @click="toKeyManagement(record)">密钥管理</a-button>
              <a-button type="link" size="small" @click="deleteHandle(record, index)">删除</a-button>
          </div>
      </a-table>
    </div>

    <a-drawer
      title="新增应用"
      placement="right"
      :width="500"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :maskClosable="false"
      :body-style="{ paddingBottom: '80px' }"
    >
      <a-form-model
        ref="saveForm"
        :model="saveForm"
        :rules="rules"
        layout="vertical"
      >
        <a-form-model-item label="应用名称" prop="applicationName">
          <a-input
            v-model="saveForm.applicationName"
            placeholder="请输入应用名称"
          />
        </a-form-model-item>
        <a-form-model-item label="应用简称" prop="applicationAbbreviation">
          <a-input
            v-model="saveForm.applicationAbbreviation"
            placeholder="请输入应用简称"
          />
        </a-form-model-item>
        <a-form-model-item label="所属企业" prop="applicationCompany">
          <a-input
            v-model="saveForm.applicationCompany"
            placeholder="请输入企业名称"
          />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="applicationCellphone">
          <a-input
            v-model="saveForm.applicationCellphone"
            placeholder="请输入联系电话"
          />
        </a-form-model-item>
        <a-form-model-item label="联系邮箱" prop="applicationEmail">
          <a-input
            v-model="saveForm.applicationEmail"
            placeholder="请输入联系邮箱"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-bottom-btns">
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          取消
        </a-button>
        <a-button type="primary" :loading="saveFormLoading" @click="saveNewApp">
          提交
        </a-button>
      </div>
    </a-drawer>
  </page-head-wrapper>
</template>

<script>
import {applicationPage, applicationSave, applicationRemove} from '@/api/app-permissions'
import {mapGetters} from 'vuex'
import {validateMobile, validateEmail} from '@/utils/validate'
const columns = [
  {
    align: 'center',
    title: '序号',
    width: '80px',
    scopedSlots: { customRender: 'serial'}
  },
  {
    align: 'center',
    title: 'AccessKey',
    dataIndex: 'applicationAccessKey'
  },
  {
    align: 'center',
    title: '应用名称',
    dataIndex: 'applicationName',
  },
  {
    align: 'center',
    title: '所属企业',
    dataIndex: 'applicationCompany',
    ellipsis: true,
    width: '20%',
  },
  {
    align: 'center',
    title: '创建时间',
    dataIndex: 'applicationSubmitTime',
  },
  {
    align: 'center',
    title: '状态',
    dataIndex: 'applicationState',
    scopedSlots: { customRender: 'status'}
  },
  {
    align: 'center',
    title: '相关操作',
    width: 240,
    scopedSlots: { customRender: 'handles'}
  },
]
let validatePhone = (rule, value, callback) => {
  value = value.trim()
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else if (!validateMobile(value)) {
    callback(new Error('手机号格式不正确'))
  } else {
    callback();
  }
}
let validateApplicationEmail = (rule, value, callback) => {
  value = value.trim()
  if (value === '') {
    callback(new Error('请输入邮箱'))
  } else if (!validateEmail(value)) {
    callback(new Error('邮箱格式不正确'))
  } else {
    callback();
  }
}

export default {
  computed: {
    ...mapGetters(['adminId'])
  },
  data() {
    return {
      data: [],
      columns,
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },

      // 搜索表单
      searchForm: {
        applicationName: '',
        applicationCompany: '',
        applicationState: ''
      },

      // 新增应用 - drawer
      visible: false,
      saveFormLoading: false,
      saveForm: {
        applicationName: '',
        applicationCompany: '',
        applicationCellphone: '',
        applicationEmail: '',
        applicationAbbreviation: '',
      },
      rules: {
        applicationName: [
          { required: true, message: '请输入应用名称', trigger: 'blur' },
        ],
        applicationCompany: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
        ],
        applicationCellphone: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
        applicationEmail: [
          { required: true, validator: validateApplicationEmail, trigger: 'blur' },
        ],
        applicationAbbreviation: [
          { required: false, message: '请输入应用简称', trigger: 'blur' },
        ]
      },
    }
  },
  created() {
    // this.getListData()
  },
  watch: {
    '$route': {
      handler: function (route) {
        let {size, current, name, company, state} = route.query
        this.pagination.pageSize = Number(size) ? Number(size) : 10
        this.pagination.current = Number(current) ? Number(current) : 1
        this.searchForm.applicationName = name || ''
        this.searchForm.applicationCompany = company || ''
        this.searchForm.applicationState = state || ''
        this.getListData()
      },
      immediate: true
    }
  },
  methods: {
    getListData() {
      this.loading = true
      applicationPage({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        applicationName: this.searchForm.applicationName,
        applicationCompany: this.searchForm.applicationCompany,
        applicationState: this.searchForm.applicationState
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.data = res.data.records
          this.pagination.total = res.data.total  

          // 当页码过大时，没有数据时，跳转到最近的一个页面
          if (res.data.records.length === 0 && res.data.total > 0 && this.pagination.current > 1) {
            this.pagination.current = Math.ceil(res.data.total / this.pagination.pageSize) 
            this.currentRoutePush()
          }

        } else {
          this.$message.error(res.msg || '网络异常，请稍后重试')
        }
      }).catch(err => {
        console.warn(err)
      }).finally(() => {
        this.loading = false
      })
    },
    // 切换分页等操作
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination, filters, sorter)
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.currentRoutePush()
    },
    // 新增应用
    saveNewApp(){
      console.log(this.saveForm)
      this.$refs.saveForm.validate(valid => {
        if (valid) {
          this.saveFormLoading = true
          applicationSave({
            ...this.saveForm,
            applicationSubmitAdminId: this.adminId,
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('新增应用成功')
              this.$refs.saveForm.resetFields()
              this.visible = false
              this.pagination.current = 1
              this.searchForm.applicationName = ''
              this.searchForm.applicationCompany = ''
              this.searchForm.applicationState = ''
              this.currentRoutePush()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.saveFormLoading = false
          })
        }
      })
    },
    // query改变
    currentRoutePush() {
      let s = new Date().getTime().toString().slice(4)
      let queryStr = 'current=' + this.pagination.current + '&size=' + this.pagination.pageSize
      if (this.searchForm.applicationName !== '') {
        queryStr += '&name=' + this.searchForm.applicationName
      }
      if (this.searchForm.applicationCompany !== '') {
        queryStr += '&company=' + this.searchForm.applicationCompany
      }
      if (this.searchForm.applicationState !== '') {
        queryStr += '&state=' + this.searchForm.applicationState
      }
      queryStr += '&s=' + s
      this.$router.push('/app-permissions/app-registry/list?' + queryStr) 
      .catch(err => {err})
    },
    // 搜索表单
    handleSearchSubmit() {
      console.log(this.searchForm)
      this.pagination.current = 1
      this.currentRoutePush()
    },
    // 重置搜索
    resetSearchForm() {
      this.pagination.current = 1
      this.searchForm.applicationName = ''
      this.searchForm.applicationCompany = ''
      this.searchForm.applicationState = ''
      this.currentRoutePush()
    },

    afterVisibleChange(val) {
      console.log('visible', val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    toDetail(record) {
      this.$router.push('/app-permissions/app-registry/detail?id=' + record.applicationId)
    },
    toKeyManagement(record) {
      this.$router.push('/app-permissions/app-registry/key-management?id=' + record.applicationId)
    },
    // 删除
    deleteHandle(record) {
      this.$confirm({
        title: '确定删除此应用信息?',
        // content: h => <div>Some descriptions</div>,
        centered: true,
        onOk: () => {
          console.log('OK');
          applicationRemove({
            ids: record.applicationId,
            applicationLastUpdateAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.currentRoutePush()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.saveFormLoading = false
          })
        },
        onCancel: () =>{
          console.log('Cancel');
        },
      })
    }
  },
}
</script>

<style scoped>

</style>