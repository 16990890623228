/* 手机号 */
export function validateMobile (str) {
    const reg = /^1(3|4|5|6|7|8|9)[0-9]{9}$/
    return reg.test(str)
}

/* 邮箱 */
export function validateEmail (str) {
    const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
    return reg.test(str)
}

/* 英文大写、小写和数字的组合, 位数在8-16位之间 */
export function validatePwd (str) {
    const reg = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)[A-Za-z0-9]{8,16}$/
    return reg.test(str)
}